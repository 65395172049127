import { createAction, props } from '@ngrx/store';
import { APIError } from '../../models/error.model';
import { Tag } from '../../models/tag.model';

export enum TagsActionsTypes {
    LoadTags = '[Dashboard Courses] Load Tags',
    LoadTagsSuccess = '[Content API] Load Tags Success',
    LoadTagsFailure = '[Content API] Load Tags Failure'
}

export const loadTags = createAction(
    TagsActionsTypes.LoadTags,
    props<{ payload?: { query?: any; fields?: any; options?: any } }>()
);
export const loadTagsSuccess = createAction(
    TagsActionsTypes.LoadTagsSuccess,
    props<{ payload: Tag[] }>()
);
export const loadTagsFailure = createAction(
    TagsActionsTypes.LoadTagsFailure,
    props<{ payload: APIError }>()
);

