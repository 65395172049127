import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'deepFilter',
    standalone: true
})
export class DeepFilterPipe implements PipeTransform {
    transform(items: any[], filterKeys: string[], filterValue: string): any {
        if (!Array.isArray(items)) {
            return items;
        }

        return items.filter(item => this.testItem(item, filterKeys, filterValue));
    }
    
    private testItem(item: any, filterKeys: string[], filterValue: string): boolean {
        const regex = new RegExp(filterValue, 'gi');

        for (let key of filterKeys) {
            const foundValue = this.deepFind(item, key);
            if (foundValue != null && regex.test(foundValue.toString())) {
                return true;
            }
        }

        return false;
    }

    private deepFind(obj: any, path: string): any {
        const paths = path.split('.');
        let current = obj;

        for (let i = 0; i < paths.length; ++i) {
            if (current[paths[i]] == undefined) {
                return undefined;
            } else {
                current = current[paths[i]];
            }
        }

        return current;
    }
}
