import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Tag } from '../../models/tag.model';
import * as tagsActions from '../actions/tags.actions';

const selectId = (entity: any) => entity?._id;

export const tagsAdapter: EntityAdapter<Tag> = createEntityAdapter<Tag>({
    selectId
});
export interface TagsState {
    tags: EntityState<Tag>;
}

export const tagsInitialState: TagsState = {
    tags: tagsAdapter.getInitialState()
};

export const tagsReducer = createReducer(
    tagsInitialState,
    // on(persistActions.resetDashboardContent, state => ({
    //     ...state,
    //     enrolledCourses: tagsInitialState.enrolledCourses,
    //     featuredCourses: tagsInitialState.enrolledCourses,
    //     courses: tagsInitialState.courses,
    //     currentCourse: tagsInitialState.currentCourse,
    //     coursesCounters: tagsInitialState.coursesCounters
    // })),
    on(tagsActions.loadTagsSuccess, (state, { payload }) => ({
        ...state,
        tags: tagsAdapter.setAll(payload, state.tags)
    }))
);

